import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import { MainContext } from '../../context/main-context';

// 3 minutes
const timeoutLength = 180000;

export default class CSASOfflineMessage extends Component {
  interval = null;

  static contextType = MainContext;

  componentDidMount = () => {
    setTimeout(this.doLogout, timeoutLength);
  };

  doLogout = () => {
    this.context.doRedirect('/logout');
  };

  render () {
    return (
      <div className='o-ckm-authentication c-ckm-form-panel'>
        <article className='c-ckm-form-panel__flex-col c-ckm-form-panel__flex-col--border'>
          <div className='o-ckm-content-panel' role='region'>
            <div className='c-ckm-form-heading-message u-els-margin-bottom-2x' />
            <p><FormattedMessage
              id='LoginPage.csasOffline.message'
              defaultMessage='ClinicalKey Student is experiencing unexpected issues. We are sorry for the inconvenience and we are working to have things back to normal as soon as possible.'
               />
            </p>
          </div>
        </article>
      </div>
    );
  }
}
